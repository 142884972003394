const rare = [
    "Sasha Ivanov",
    "First Bitcoin transaction",
    "10 Grands Pizza Boy",
    "Colored coins",
    "Vitalik Buterin presented ETH conception",
    "Silk Road launch",
    "Satoshi Nakamoto created Bitcoin",
    "Silk Road shut down",
    "Charlie Lee created Litecoin",
    "Billie Marcus brought Doge to life",
    "Nick Sabo run BitGold",
    "Justin Sun launched TRON",
    "CryptoPunks went live",
    "Pavel Durov presented TON",
    "CryptoKitties made ETH network fall",
    "McAfee pledged to eat his manhood",
    "Sasha Ivanov & Vostok",
    "The very first NFT project Etheria",
    "Ethereum launch",
    "Sasha Ivanov launched Waves Platform",
    "Ethereum Classic fork",
    "Bitcoin Futures",
    "SEC trying to suspend TON",
    "FB & Google blocked Crypto Advertising",
    "CryptoKitties more than a 1 million transactions",
    "Uniswap launch",
    "Facebook reveals Libra",
    "Waves introducing Gravity Hub",
    "SEC blocked TON innitiative",
    "Libra stopped development",
    "BTC-E / WEX crash",
    "Pancakeswap launch",
    "Banksy burst into NFT",
    "Forbes sold NFT cover for $333 333",
    "AAVE protocol launch",
    "Rise of the Beeple",
    "Tesla accepted Bitcoin payments",
    "Waves Ducks",
    "Etheria reached $1.4 million",
    "Most expensive Ducks in the History of Ducks",
    "Musk shiling BTC"
];

export default rare;